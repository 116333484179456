import { BaseModel } from "./base.model";

export class User extends BaseModel {
  constructor(
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public adress?: string,
    public postal_code?: string,
    public city?: string,
    public country?: string,
    public role?: string,
    public access_code?: string,
    public password?: string,
    public additional_info?: string,
    public registration_completed?: string,
    public profile_image_url?: string,
    public preferred_livestream?: string
  ) {
    super();
  }
}
