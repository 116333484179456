<template>
  <div class="root">
    <div class="container">
      <ul class="progressbar">
        <li
          v-for="i in stepsArray.length"
          :key="i"
          :class="stepsArray[i-1]"
        ></li>
      </ul>

    </div>
  </div>
</template>
<script>
export default {
  props: ["length", "stepsArray", "config"],
  // watch: {
  //   stepsArray: function () {
  //     this.stepsArray.push(this.isValid)
  //   }
  // }
};
</script>
<style scoped>
.container {
  width: 100%;
  position: relative;
  z-index: 1;
}
.progressbar li {
  list-style-type: none;
  float: left;
  width: 13%;
  position: relative;
  text-align: center;
}
.progressbar {
  counter-reset: step;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  /* border: 2px solid #bebebe; */
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 25%;
  line-height: 30px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
/* .progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
} */
.progressbar li.valid:before{
  border-color: #669644;
  background: #82BF56;
  color: white
}
.progressbar li.valid:after{
  background: #82BF56;
}
.progressbar li.valid + li:after{
  background: #82BF56;
}
.progressbar li.valid + li:before{
  border-color: #669644;
  background: #82BF56;
  color: white
}

.progressbar li.active:before{
  border-color: #0c61a7 !important;
  background: #118bee !important;
  color: white
}
.progressbar li.active:after{
  border-color: #0c61a7 !important;
  background: #118bee !important;
}
.progressbar li.active + li:after{
  border-color: #0c61a7 !important;
  background: #118bee !important;
}
/* .progressbar li.active + li:before{
  border-color: #669644;
  background: #118bee;
  color: white
} */

.progressbar li.inactive:before{
  border-color: #717171;
  background: #979797 !important;
  color: white
}
.progressbar li.inactive:after{
  background: #979797 !important;
}
.progressbar li.inactive + li:after{
  background: #979797 !important;
}
.progressbar li.inactive + li:before{
  border-color: #717171;
  background: #979797;
  color: white
}

.progressbar li.invalid:before{
   border-color: #BD3E31;
  background: #E74C3C;
  color: white
}
.progressbar li.invalid:after{
  background: #E74C3C;
}
.progressbar li.invalid + li:after{
  background: #E74C3C;
}
.progressbar li.invalid + li:before{
  border-color: #BD3E31;
  background: #E74C3C;
  color: white
}
.progressbar li:first-child:after{
  content: none;
}
</style>